import * as React from "react"
import {graphql} from "gatsby";
import { render } from 'datocms-structured-text-to-html-string';
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";

import SEO from './../components/SEO';


import StaticPageTemplate from "./../components/staticPageTemplate"

export const getContact = graphql`
query getContact {
  datoCmsAboutme{
    body {
      value
    }
    title
  }
}
`

const ContactPageContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: center;
`

const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  & form{
    width:85%;
    ${breakpoint("tablet") `
        width: 380px;
    `}
    ${breakpoint("desktop") `
        width: 500px;
    `}
  }

  & > form{
    display: flex;
    flex-direction: column;
  }

  & form > div{
    margin-top: 3px;
  }
`

const ContactFormHeader = styled.span`
  font-size: 24px;
  margin-bottom: 10px;

`
const InputLabel = styled.label`
  margin-top: 5px;
  font-family: "Lato",sans-serif;
  color: #333;
  font-size: 1.1em;
`

const SingleInput = styled.input`
  border: 0;
  padding: 5px 3px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  transition: border .3s;
  color: #333;

  width: 100%;

  &:focus{
    outline: none;
    border-bottom: 1px solid #FF7171;
  }
`

const MultiInput = styled.textarea`
  overflow: auto;
  border: none;
  resize: none;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
  transition: border .3s;
  color: #333;

  width: 100%;

  &:focus{
    outline: none;
    border-bottom: 1px solid #FF7171;
  }
`

const SubmitButton = styled.button`
  border: 1px solid #FF7171;
  border-radius: 10px;
  background: transparent;
  padding: 8px 0;
  margin-top: 7px;
  transition: box-shadow .2s;
  font-family: "Lato", sans-serif;
  font-size: 1.1em;

  &:focus{
    outline: none;
  }

  &:hover{
    box-shadow: 0px 0px 8px 0px #ccc;
    background: #fdfdfd;
  }

  &:active{
    background: #eee;
  }
`

const ContactPage = ({data}) => {

  return (
    <StaticPageTemplate title={data.datoCmsAboutme.title}>

        <SEO title="Miejsce Pamięci Dzieci Utraconych w Dębicy - kontakt" link="https://debica-dzieciutracone.pl/kontakt" ogTitle="Kontakt - Miejsce Pamięci Dzieci Utraconych w Dębicy" description="Kontakt do wolontariuszy hospicjum domowego w Dębicy i osób zaangażowanych w symboliczny pochówki."/>

        <ContactPageContainer>
            <div className="DatoCMS" style={{"width":"100%"}} dangerouslySetInnerHTML={{__html: render(data.datoCmsAboutme.body.value)}}></div>
            <ContactForm>
                <ContactFormHeader>Formularz kontaktowy</ContactFormHeader>
                <form action="https://formsubmit.io/send/10c5484a-273d-47c7-a76a-b9d68017262f" method="POST">
                    <InputLabel>E-mail</InputLabel>
                    <SingleInput name="email" required/>
                    <InputLabel>Treść</InputLabel>
                    <MultiInput name="tresc" required rows="6"/>
                    <SubmitButton type="submit">Wyślij</SubmitButton>
                </form>
            </ContactForm>
       </ContactPageContainer>
    </StaticPageTemplate>
  )
}

export default ContactPage;